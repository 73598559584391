<template>
  <v-dialog v-model='dlgShow' scrollable persistent max-width='400px'>
    <v-card>
      <v-card-title>
        <div class='title font-weight-medium'>{{ title }} role</div>
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model.trim='dlgModel.FullName'
          :disabled='dlgType === 2'
          name='name'
          label='FullName:'
          v-validate='{ required: true }'
          :error='!!errors.has("name")'
          :error-messages='errors.first("name")'
        >
        </v-text-field>
        <v-text-field
          v-model.trim='dlgModel.Email'
          :disabled='dlgType > 0'
          name='email'
          label='Email:'
          v-validate='{ required: true, email: true }'
          :error='!!errors.has("email")'
          :error-messages='errors.first("email")'
        >
        </v-text-field>
        <v-text-field
          v-model.trim='dlgModel.MobileNo'
          :disabled='dlgType === 2'
          name='mobile'
          label='Mobile Number:'
          v-validate='{ required: true, min: 10, max: 20 }'
          :error='!!errors.has("mobile")'
          :error-messages='errors.first("mobile")'
        >
        </v-text-field>
        <v-text-field
          v-model.trim='dlgModel.Password'
          v-if='dlgType === 0'
          name='pwd'
          label='Password:'
          v-validate='{ required: true, min: 6 }'
          :error='!!errors.has("pwd")'
          :error-messages='errors.first("pwd")'
        >
        </v-text-field>
        <v-autocomplete
          v-if='dlgType === 0 || dlgType === 2'
          :items='roles'
          v-model='dlgModel.Role'
          label='Role:'
          name='role'
          v-validate='{ required: true }'
          :error='!!errors.has("role")'
          :error-messages='errors.first("role")'
        >
        </v-autocomplete>
        <v-textarea
          no-resize
          label='Address:'
          :disabled='dlgType === 2'
          v-model.trim='dlgModel.Address'
          rows='2'
        ></v-textarea>
        <v-text-field
          v-model.trim='dlgModel.City'
          :disabled='dlgType === 2'
          label='City:'
        ></v-text-field>
        <v-text-field
          v-model.trim='dlgModel.PostalCode'
          :disabled='dlgType === 2'
          label='Postal Code:'
        ></v-text-field>
        <div v-show='submit.error'>
          <v-list two-line>
            <v-list-tile>
              <span class='red--text'>{{ submit.message }}</span>
            </v-list-tile>
          </v-list>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn flat :disabled='submit.working' @click='closeDlg'>Close</v-btn>
        <v-btn
          :disabled='dlgType === 2'
          color='primary darken-1'
          flat
          :loading='submit.working'
          @click='saveDlg'
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import WebH from '../../../api/web';
export default {
  props: {
    dlgShow: {
      type: Boolean,
      default: false
    },
    dlgType: {
      type: Number,
      default: 2
    },
    item: Object,
    roles: Array
  },
  data() {
    return {
      submit: {
        error: false,
        working: false,
        message: ''
      },
      dlgModel: {}
    };
  },
  computed: {
    title() {
      return this.dlgType === 0
        ? 'Create'
        : this.dlgType === 1
        ? 'Edit'
        : 'View';
    }
  },
  watch: {
    item: function() {
      if (this.dlgType === 0) {
        this.dlgModel = {
          FullName: '',
          Email: '',
          MobileNo: '',
          Address: '',
          Password: '',
          City: '',
          PostalCode: '',
          Role: ''
        };
      } else {
        this.dlgModel = {
          FullName: this.item.FullName,
          Email: this.item.Email,
          MobileNo: this.item.MobileNo,
          Address: this.item.Address,
          City: this.item.City,
          PostalCode: this.item.PostalCode
        };
      }
      if (this.dlgType === 2) {
        this.dlgModel.Role = this.item.Role;
      }
    }
  },
  methods: {
    closeDlg() {
      this.errors.clear();
      this.submit = {
        error: false,
        working: false,
        message: ''
      };
      this.dlgModel = {
        Name: '',
        Description: ''
      };
      this.$emit('close');
    },
    async saveDlg() {
      const res = await this.$validator.validateAll();
      if (!res) return;
      if (this.dlgType === 0) {
        this.api_create(this.dlgModel);
      } else {
        this.api_update(this.dlgModel);
      }
    },
    async api_create(data) {
      this.submit.working = true;
      const res = await WebH.Post('/users', data);
      this.submit.working = false;
      if (res.Success) {
        this.closeDlg();
      } else {
        this.submit.error = true;
        this.submit.message = res.Data;
      }
    },
    async api_update(data) {
      this.submit.working = true;
      const res = await WebH.Put('/users/update?email=' + data.Email, data);
      this.submit.working = false;
      if (res.Success) {
        this.closeDlg();
      } else {
        this.submit.error = true;
        this.submit.message = res.Data;
      }
    }
  }
};
</script>

<style scoped></style>
