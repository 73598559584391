<template>
  <v-data-table
    :headers='headers'
    :items='users'
    item-key='Id'
    :rows-per-page-items='rows_per_page'
    :loading='loading'
    class='elevation-1'
  >
    <template slot='items' slot-scope='props'>
      <tr>
        <td>{{ props.item.FullName }}</td>
        <td>{{ props.item.Email }}</td>
        <td>{{ props.item.MobileNo }}</td>
        <td>{{ props.item.Role }}</td>
        <td style='text-align: center'>
          <button class='linkBtn' type='button' @click='viewDlg(props.item)'>
            View
          </button>
          <button class='linkBtn' type='button' @click='editDlg(props.item)'>
            Edit
          </button>
          <button class='linkBtn' type='button' @click='deleteDlg(props.item)'>
            Delete
          </button>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: ['users', 'loading'],
  data() {
    return {
      rows_per_page: [25, 50],
      headers: [
        { text: 'Full Name', value: 'FullName', sortable: true, width: '20%' },
        { text: 'Email', value: 'Email', sortable: true, width: '20%' },
        { text: 'Mobile', value: 'MobileNo', sortable: true, width: '10%' },
        { text: 'Role', value: 'Role', sortable: true, width: '10%' },
        {
          text: 'Actions',
          value: '',
          sortable: false,
          width: '20%',
          align: 'center'
        }
      ]
    };
  },
  methods: {
    viewDlg(item) {
      this.$emit('view', item);
    },
    editDlg(item) {
      this.$emit('edit', item);
    },
    deleteDlg(item) {
      this.$emit('delete', item);
    }
  }
};
</script>

<style scoped></style>
