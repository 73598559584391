<template>
  <v-container grid-list-xl fluid>
    <v-layout row wrap>
      <v-flex sm6>
        <div class='headline green--text text--darken-4 font-weight-medium'>
          Users
        </div>
      </v-flex>
      <v-flex xs12 sm12 lg12>
        <v-btn color='primary' @click='createDlg'>
          <v-icon class='iconText'>fa fa-plus</v-icon>
          New User
        </v-btn>
      </v-flex>
      <v-flex sm6 xs12 lg12>
        <v-card>
          <v-card-text class='pa-0'>
            <UsersDisplay
              :users='users'
              :loading='loading'
              @view='viewDlg'
              @edit='editDlg'
              @delete='deleteDlg'
            ></UsersDisplay>
            <UserDlg
              :roles='roles'
              :dlg-type='dlgType'
              :dlg-show='dlgShow'
              :item='selectedItem'
              @close='closeDlg'
            ></UserDlg>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import WebH from '../../api/web';
import UsersDisplay from '../../components/account/users/UsersDisplay';
import UserDlg from '../../components/account/users/UserDlg';
export default {
  components: {
    UsersDisplay,
    UserDlg
  },
  created() {
    this.api_getRoles();
    this.api_get();
  },
  data() {
    return {
      users: [],
      roles: [],
      loading: false,
      selectedItem: null,
      dlgShow: false,
      dlgType: 0
    };
  },
  methods: {
    closeDlg() {
      this.dlgShow = false;
    },
    createDlg() {
      this.selectedItem = null;
      this.dlgType = 0;
      this.dlgShow = true;
    },
    viewDlg(item) {
      this.selectedItem = Object.assign({}, item);
      this.dlgType = 2;
      this.dlgShow = true;
    },
    editDlg(item) {
      this.selectedItem = Object.assign({}, item);
      this.dlgType = 1;
      this.dlgShow = true;
    },
    async deleteDlg(item) {
      const res = await this.$notifyDlg.show(
        'Do you really want delete this user?',
        'Delete user',
        'warning',
        true
      );
      if (res) {
        this.api_delete(item.Email);
      }
    },
    async api_getRoles() {
      this.roles = [];
      const res = await WebH.Get('/roles', 'roles');
      if (res.Success) {
        for (let i = 0; i < res.Data.length; i++) {
          this.roles.push(res.Data[i].Name);
        }
      }
    },
    async api_get() {
      this.loading = true;
      const res = await WebH.Get('/users', 'users');
      this.loading = false;
      if (res.Success) {
        this.users = res.Data;
      }
    },
    async api_delete(data) {
      const res = await WebH.Delete('/users/del?email=' + data);
      if (res.Success) {
        this.$notify({
          type: 'info',
          text: 'Successfully deleted the user'
        });
      } else {
        await this.$notifyDlg.show(res.Data, 'Error', 'error');
      }
    }
  }
};
</script>

<style scoped></style>
